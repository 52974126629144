import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Layout } from "../components";
import Cards from "../blocks/cards";
import { Icon } from "reflexjs";
import Block from "../components/cta.js";
import { Link } from "gatsby";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Layout mdxType="Layout">
      <Block heading="Eviction Metrics" text="Aggregate City of Milwaukee eviction data and case outcomes. View yearly and monthly trends. See top evictors by lead plantiff." mdxType="Block" /> 
      <Cards py="2|2|2|2" subheading="" heading="" text="" cards={[{
        heading: "City of Milwaukee Eviction Filings",
        text: "",
        image: {
          src: "/images/eviction-filings.png",
          alt: ""
        },
        link: <a display="inline-flex" alignItems="center" variant="text.link">
        <Link to="/general_eviction/sixth-post/" mdxType="Link">Visualizations</Link>  <Icon name="arrow-right" size="4" ml="2" mdxType="Icon" />
        </a>
      }, {
        heading: "City of Milwaukee Eviction Judgements",
        text: "",
        image: {
          src: "/images/evictions-jdmt.png",
          alt: ""
        },
        link: <a display="inline-flex" alignItems="center" variant="text.link">
        <Link to="/general_eviction/seventh-post/" mdxType="Link">Visualizations</Link>  <Icon name="arrow-right" size="4" ml="2" mdxType="Icon" />
        </a>
      }, {
        heading: "Eviction Filings by Lead Plaintiff",
        text: "",
        image: {
          src: "/images/lead-plantiff.png",
          alt: ""
        },
        link: <a display="inline-flex" alignItems="center" variant="text.link">
        <Link to="/general_eviction/tenth-post/" mdxType="Link">Visualizations</Link>  <Icon name="arrow-right" size="4" ml="2" mdxType="Icon" />
        </a>
      }, {
        heading: "Final Disposition of Cases Filed in 2016-2019",
        text: "",
        image: {
          src: "/images/eviction-dispo.png",
          alt: ""
        },
        link: <a display="inline-flex" alignItems="center" variant="text.link">
        <Link to="/general_eviction/eleventh-post/" mdxType="Link">Visualizations</Link>  <Icon name="arrow-right" size="4" ml="2" mdxType="Icon" />
        </a>
      }, {
        heading: "Affidavit of Non-Compliance",
        text: "",
        image: {
          src: "/images/aff-noncompliance.png",
          alt: ""
        },
        link: <a display="inline-flex" alignItems="center" variant="text.link">
        <Link to="/general_eviction/thirteen-post/" mdxType="Link">Visualizations</Link>  <Icon name="arrow-right" size="4" ml="2" mdxType="Icon" />
        </a>
      }, {
        heading: "Writ of Restitution",
        text: "",
        image: {
          src: "/images/writ-restitution.png",
          alt: ""
        },
        link: <a display="inline-flex" alignItems="center" variant="text.link">
        <Link to="/general_eviction/twelfth-post/" mdxType="Link">Visualizations</Link>  <Icon name="arrow-right" size="4" ml="2" mdxType="Icon" />
        </a>
      }, {
        heading: "Stipulated Dismissal Followed by Affidavit of Noncompliance",
        text: "",
        image: {
          src: "/images/stip-noncompliance.png",
          alt: ""
        },
        link: <a display="inline-flex" alignItems="center" variant="text.link">
        <Link to="/general_eviction/fourteenth-post/" mdxType="Link">Visualizations</Link>  <Icon name="arrow-right" size="4" ml="2" mdxType="Icon" />
        </a>
      }]} mdxType="Cards" />
    </Layout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      